@use 'sass:math';
@import 'main.scss';

.widget {
  position: absolute;
  background-color: $main-bg-color;
  font-size: $font-size;
  
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  &-container{
    width: 25em;
    height: 15em;

    &--toolbar{
      background-color: $widget-bg-color-main;
    }
  }
}