@import '../../main.scss';

.supplier{
    &-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        
    
        &:hover {
            cursor: pointer;
        }
    
        &--image{
            height: 2em;
            width: 2em;
            position: relative;
    
            > img{
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                
                max-width: 2em;
                max-height: 2em;
            }
        }
    
        &--footer-text{
            font-size: 0.6em;
            color: #7d7d7d;
        }
    }

    &s-container{
        &--footer-text {
            color: $font-color-secondary;
    
            font-size: 0.7em;
        }
    }
}